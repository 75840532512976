import React, { Component } from "react";
import ReactDom from "react-dom";
import Dialog from "react-bootstrap-dialog-v2";
import AutoCompleteHelper from "./AutoCompleteHelper.js";
import ReactTable from "react-table";
import "react-table/react-table.css";
import CurrencyInput from "react-currency-input";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Select from "react-select";
import {
  OverlayTrigger,
  Popover,
  Image,
  Checkbox,
  Modal,
  Breadcrumb,
  Button,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
} from "react-bootstrap";
import { Column, Row } from "simple-flexbox";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Loader from "react-loader";
import NumberFormat from "react-number-format";
import WS from "../services/webservice.js";
import cep from "cep-promise";
import Icon from "react-icons-wrapper";
import Resizer from "react-image-file-resizer";
import { base64ToBlob, blobToBase64 } from "base64-blob";
import MathJax from "react-mathjax2";
import { SketchPicker } from "react-color";
import AutoCompleteCity from "./AutoCompleteCity";
import { Container } from "react-bootstrap/lib/Tab.js";
import styled from "styled-components";
import { versao } from "../go-versao.js";

//const sharp = require('sharp');
//var resizebase64 = require('resize-base64');
var user;
var admin = "inherit";
var centrais;
var allCentrais;
var ref;

// const ErrorMessage = styled.p`
//   color: red;
//   font-weight: bold;
// `

const ErrorMessage = styled.p`
  color: black;
  font-weight: bold;
  font-size: 1.2em;
`;
const List = styled.ul`
  list-style-type: disc;
  padding-left: 5;
`;
const ListItem = styled.li`
  color: red;
  font-weight: bold;
`;

class CadastroHelper extends Component {
  constructor(props) {
    super(props);
    this.subis = {};
    this.salvar = this.salvar.bind(this);
    this.sendsalvar = this.sendsalvar.bind(this);
    this.getList = this.getList.bind(this);
    this.orgSubLists = this.orgSubLists.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDataTimeOut = this.fetchDataTimeOut.bind(this);
    this.saveStateValues = this.saveStateValues.bind(this);
    this.newRecordData = this.newRecordData.bind(this);
    this.newClicked = this.newClicked.bind(this);
    this.funcaoOutras = this.funcaoOutras.bind(this);
    this.getSubTipo = this.getSubTipo.bind(this);
    this.renderJsonList = this.renderJsonList.bind(this);
    this.renderjsonarray = this.renderjsonarray.bind(this);
    this.renderJsonLinha = this.renderJsonLinha.bind(this);
    this.setSubs = this.setSubs.bind(this);
    this.saveFilho = this.saveFilho.bind(this);
    this.setSubCadastros = this.setSubCadastros.bind(this);
    this.modalHide = this.modalHide.bind(this);
    this.fotoShow = this.fotoShow.bind(this);
    this.fotoHide = this.fotoHide.bind(this);
    this.convertBase64 = this.convertBase64.bind(this);
    this.ClickExtertno = this.ClickExtertno.bind(this);
    this.externalNew = this.externalNew.bind(this);
    this.resize64 = this.resize64.bind(this);
    this.componenteAutocomplete = this.componenteAutocomplete.bind(this);
    this.updateGeo = this.updateGeo.bind(this);
    this.ws = new WS();
    this.state = {
      center: { lat: -25.475015, lng: -49.2911143 },
      address: [],
      loaded: true,
      central: "",
      cookie: false,
      list: [],
      pages: null,
      loading: true,
      show: false,
      new: false,
      edit: false,
      id: 0,
      record: this.newRecordData(),
      modulos: [],
      modulosTable: [],
      modulosSelected: [],
      sendModulos: [],
      actionsCheck: [],
      sublistas: {},
      showModulos: false,
      fotourl: "",
      fototipo: 1,
      showfoto: false,
    };
  }
  resize64 = async (file) => {
    let saida = await base64ToBlob(file);
    saida = await this.convertBase64resize(saida, 350, 350);
    return saida;
  };
  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  async updateGeo(ref, records) {
    //console.log('en trou geo')
    let geo = this.props.dados.geo;
    if (ref === geo.rua || ref === geo.numero || ref === geo.bairro || ref === geo.cidade || ref === geo.estado) {
      //console.log('é um do geo')
      if (
        records[geo.rua] !== "" &&
        records[geo.numero] !== "" &&
        records[geo.bairro] !== "" &&
        records[geo.cidade] !== "" &&
        records[geo.estado] !== ""
      ) {
        this.timeoutAutoComplete = setTimeout(() => {
          this.componenteAutocomplete(records[geo.rua], records[geo.numero], records[geo.bairro], records[geo.cidade], records[geo.estado]);
        }, 3000);
      }
      // cep: 'cep_instalacao',
      // rua: 'endereco_instalacao',
      // numero: 'numero_local_instalaca',
      // bairro: 'bairro_instalacao',
      // cidade: 'municipio_instalacao',
      // estado: 'uf_instalacao',
      // latitude: 'latitude_intalacao',
      // longitude: 'longitude_instalacao',
    }
  }

  async componenteAutocomplete(rua, numero, bairro, cidade, estado) {
    let result = await this.ws.sendAutocomplete(rua, numero, bairro, cidade, estado);
    if (!!result) {
      if (result.length > 0) {
        let itens = result[0];
        this.saveStateValues(this.props.dados.geo.latitude, itens.latitude, null);
        this.saveStateValues(this.props.dados.geo.longitude, itens.longitude, null);
      }
    }
  }

  convertBase64resize = async (image, wmax, hmax) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        image,
        wmax,
        hmax,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  };

  async fotoShow(url, tipo) {
    if (!tipo) {
      tipo = 1;
    }
    this.setState({
      fotourl: url,
      fototipo: tipo,
      showfoto: true,
    });
  }
  async fotoHide() {
    this.setState({
      fotourl: "",
      showfoto: false,
    });
  }
  async orgSubLists(record) {
    //konsole.log('orgSubLists', record)
    if (this.props.dados.subtipos) {
      for (let index = 0; index < this.props.dados.subtipos.length; index++) {
        const element = this.props.dados.subtipos[index];
        if (!this.state.sublistas[element.tipo]) {
          if (element.p === "get") {
            //konsole.log('element 2',element)
            let order = null;
            // if (element.order) {
            //   order = '-' + element.order.get + '|' + record[element.order.local]
            // }
            this.getSubTipo(element.tipo, element.id, element.txt, element.filter, order);
          } else if (element.p === "array") {
            this.arraySubTipo(element.tipo, element.array);
          } else if (element.p === "oarray") {
            this.arraySubTipoObjeto(element.tipo, element.array);
          }
        }
      }
    }
  }
  async findCidadeByName(campo, subtipo, cidade, pai) {
    let dados = await this.filtraNovos(cidade, subtipo);
    this.saveStateValues(campo, dados[0].id, pai);
  }
  async validaCep(ref, pai, campo) {
    let vcep = this.getStateValue(ref, pai);
    if (vcep.length < 8) {
      return null;
    }
    cep(vcep).then(async (result) => {
      if (result.cep) {
        console.log('cep', result);
        if (campo.cepdados) {
          this.saveStateValues(campo.cepdados.uf, result.state, pai);
          this.saveStateValues(campo.cepdados.cidade, result.city, pai);
          this.saveStateValues(campo.cepdados.bairro, result.neighborhood, pai);
          this.saveStateValues(campo.cepdados.rua, result.street, pai);
        }
      }
    });
  }
  arraySubTipoObjeto(tipo, array) {
    let sublista = this.state.sublistas;
    let resposta = [];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      resposta.push({
        id: element.id,
        text: element.txt,
        value: element.id,
        label: element.txt,
      });
    }
    sublista[tipo] = resposta;
    this.setState({
      sublistas: sublista,
    });
    //konsole.log('mirrorState helper', sublista)
    if (this.props.dados.mirrorState) {
      this.props.dados.mirrorState({
        sublistas: sublista,
      });
    }
  }
  arraySubTipo(tipo, array) {
    let sublista = this.state.sublistas;
    let resposta = [];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      resposta.push({
        id: element,
        text: element,
        value: element,
        label: element,
      });
    }
    sublista[tipo] = resposta;
    this.setState({
      sublistas: sublista,
    });
    //konsole.log('mirrorState helper', sublista)
    if (this.props.dados.mirrorState) {
      this.props.dados.mirrorState({
        sublistas: sublista,
      });
    }
  }
  async filtraNovos(texto, subtipo) {
    let sub = this.props.dados.subtipos.find((x) => x.tipo === subtipo);
    //konsole.log(subtipo,[texto, sub])
    let resposta = [];
    if (sub) {
      if (sub.search) {
        let filter = sub.filter;
        if (!filter) {
          filter = [];
        }
        filter = filter.filter((obj) => obj.id !== sub.search);
        filter.push({ id: sub.search, value: texto });
        let response = await this.ws.getSubTipo(sub.tipo, filter, null);
        response = await response.json();

        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          resposta.push({
            ...element,
            id: element[sub.id],
            text: element[sub.txt],
            value: element[sub.id],
            label: element[sub.txt],
          });
        }
        let sublista = this.state.sublistas;
        //konsole.log(sub.tipo,resposta)
        sublista[sub.tipo] = resposta;
        this.setState({
          sublistas: sublista,
        });
      }
    }
    return resposta;
  }
  getSubTipo(tipo, id, texto, filter, order) {
    if (this.props.dados.requests.listSub) {
      this.ws.getSubTipo(tipo, filter, order).then(async (response) => {
        response = await response.json();
        let resposta = [];
        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          resposta.push({
            ...element,
            id: element[id],
            text: element[texto],
            value: element[id],
            label: element[texto],
          });
        }
        let sublista = this.state.sublistas;
        sublista[tipo] = resposta;
        this.setState({
          sublistas: sublista,
        });
        //konsole.log('mirrorState helper', sublista)
        if (this.props.dados.mirrorState) {
          this.props.dados.mirrorState({
            sublistas: sublista,
          });
        }
      });
    }
  }
  renderJsonLinha(label, data) {
    return (
      <Row>
        <Column>{label.toString()}</Column>
        <Column>{data.toString()}</Column>
      </Row>
    );
  }
  renderjsonarray(dados, campo) {
    //console.log('linha array 1', [dados, campo])
    if (!Array.isArray(dados)) {
      dados = [];
    }
    if (!Array.isArray(campo)) {
      campo = [];
    }
    // console.log('linha array 2', [dados, campo])
    return (
      <React.Fragment>
        <Row>
          {campo.map((x) => {
            return <Column>{x.label}</Column>;
          })}
        </Row>
        {dados.map((y) => {
          return (
            <Row style={{ border: "1px solid black" }}>
              {campo.map((x) => {
                if (x.type === "action") {
                  return (
                    <Column flexGrow={1} style={{ padding: 5 }}>
                      <Image
                        src={require("./images/edit.png")}
                        style={{ height: 30, width: 30 }}
                        onClick={() => {
                          x.func(y[x.param]);
                        }}></Image>
                    </Column>
                  );
                } else {
                  return (
                    <Column flexGrow={2} style={{ padding: 5 }}>
                      {y[x.ref]}
                    </Column>
                  );
                }
              })}
            </Row>
          );
        })}
      </React.Fragment>
    );
    // return (
    //   <ReactTable
    //   data={dados}
    //   columns={campo}
    //   pages={1}
    //   loading={false}
    //   onFetchData={() => {}}
    //   previousText='Anterior'
    //   nextText='Próxima'
    //   loadingText='Carregando...'
    //   noDataText='Nenhum dado encontado'
    //   pageText='Página'
    //   ofText='de'
    //   rowsText='linhas'
    //   defaultPageSize={dados.length + 3}
    //   className="-striped -highlight"
    //   />
    // )
  }
  renderJsonList(dados) {
    if (dados) {
      let infos = JSON.parse(dados);
      let chaves = Object.keys(infos);
      // console.log('jsonlist 2', dados);
      // console.log('jsonlist 3', chaves);
      // console.log('jsonlist 4', Object.values(infos));

      // for (let index = 0; index < chaves.length; index++) {
      //   const element = chaves[index];
      //   console.log('jsonlist 4', [element, infos[element]]);
      //   saida += this.renderJsonLinha(element, infos[element]);
      // }

      return (
        <React.Fragment>
          {chaves.map((chave) => {
            return (
              <Row>
                <Column style={{ padding: 5 }}>{chave.toString()}</Column>
                <Column style={{ padding: 5 }}>{infos[chave].toString()}</Column>
              </Row>
            );
          })}
        </React.Fragment>
      );
    } else {
      return "";
    }
  }
  renderCheckBox() {}
  renderFieldsOrganizados(field, pai, referencia) {
    return (
      <React.Fragment>
        {field.organizado.map((linha, indice) => {
          return (
            <Row className="show-grid">
              {linha.map((campo, index) => {
                if (campo.hide) {
                  return null;
                } else if (campo.tipo === "campo" && !campo.hide) {
                  let campoindex = field.campos.findIndex((x) => x.ref === campo.ref);
                  if (campoindex >= 0) {
                    let x = field.campos[campoindex];
                    return (
                      <Col xm={6} style={{ flex: 2, flexDirection: "row", marginTop: 3 }}>
                        <Row>
                          {this.renderLabel(x.label, campo.hidelabel !== undefined ? campo.hidelabel : false)}
                          {this.renderFieldUnic(field, pai, referencia, x, 0)}
                        </Row>
                      </Col>
                    );
                  } else {
                    return null;
                  }
                } else if (campo.tipo === "fct") {
                  return <Col sm>{!!campo.function && campo.function()}</Col>;
                } else if (campo.tipo === "textoMin") {
                  return (
                    <Col sm>
                      <div style={{ fontSize: 9, textDecoration: "italic" }}>
                        {campo.texto}
                        {!!campo.required ? " ¹" : ""}
                      </div>
                    </Col>
                  );
                } else if (campo.tipo === "texto") {
                  return (
                    <Column flexGrow={2} style={{ paddingRight: 15, marginTop: 2 }}>
                      {this.renderLabel(campo.texto, false)}
                    </Column>
                  );
                } else if (campo.tipo === "formula") {
                  return (
                    <Column flexGrow={2} style={{ paddingRight: 15 }}>
                      <MathJax.Context input="ascii">
                        <MathJax.Node inline>{campo.texto}</MathJax.Node>
                      </MathJax.Context>
                    </Column>
                  );
                } else if (campo.tipo === "hr") {
                  return (
                    <div
                      style={{
                        backgroundColor: "black",
                        height: 2,
                        width: "100%",
                        margin: 5,
                      }}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </Row>
          );
        })}
      </React.Fragment>
    );
  }

  renderFields(field, pai) {
    if (field.campos) {
      let referencia = this.state.record;
      if (pai) {
        referencia = this.state.record[pai];
      }
      if (!referencia) {
        return;
      }
      if (field.organizado) {
        return this.renderFieldsOrganizados(field, pai, referencia);
      } else {
        return (
          <div>
            {field.campos.map((m, i) => {
              return (
                <Row>
                  {m.type !== "hidden" && this.renderLabel(m.label, false)}
                  {this.renderFieldUnic(field, pai, referencia, m, i)}
                </Row>
              );
            })}
          </div>
        );
      }
    }
  }
  renderLabel(label, hidelabel) {
    let largura = this.props.dados.larguralabel ? this.props.dados.larguralabel : 150;
    if (hidelabel) {
      return null;
    } else {
      return (
        <Column style={{ paddingRight: 15, minWidth: largura }}>
          <ControlLabel>{label}</ControlLabel>
        </Column>
      );
    }
  }

  renderFieldUnic(field, pai, referencia, m, i) {
    if (this.state.edit) {
      if (m.ref !== "senha") {
        if (referencia[m.ref] === undefined) {
          return null;
        }
      }
    }
    if (m.hide) {
      return <></>;
    } else if (m.type === "action") {
      //this.getSubTipo(m.listtype);
      return (
        <Column flexGrow={2} style={{ paddingRight: 15, marginTop: 2 }}>
          <Image
            src={require("./images/edit.png")}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              m.func(referencia[m.param]);
            }}></Image>
        </Column>
      );
    } else if (m.type === "selectold") {
      //this.getSubTipo(m.listtype);
      return (
        <Column flexGrow={2} style={{ paddingRight: 15, marginTop: 2 }}>
          <Select2
            ref={m.ref}
            value={referencia[m.ref]}
            data={this.state.sublistas[m.listtype] ? this.state.sublistas[m.listtype] : []}
            onChange={(e) => {
              //konsole.log('onchange', e)
            }}
            onSelect={(e) => {
              this.saveStateValues(m.ref, this.getSelectedOptionsValue(e.target.selectedOptions), pai);
            }}
            onUnselect={(e) => {
              this.saveStateValues(m.ref, this.getSelectedOptionsValue(e.target.selectedOptions), pai);
            }}
            options={{
              placeholder: m.placeHolder,
              width: "100%",
            }}
          />
        </Column>
      );
    } else if (m.type === "checkboxnextedstringvirgula") {
      let lista = this.state.sublistas[m.listtype] ? this.state.sublistas[m.listtype] : [];
      let valor = referencia[m.ref] ? referencia[m.ref] : "";

      let lastcat = "";
      return (
        <Column flexGrow={2} style={{ paddingRight: 15, marginTop: 2 }}>
          {lista.map((a, b) => {
            let ok = false;
            if (valor.indexOf(a.id) >= 0) {
              ok = true;
            }
            let pcat = false;
            if (a.cat !== undefined) {
              if (a.cat !== lastcat) {
                lastcat = a.cat;
                pcat = true;
              }
            }
            return (
              <React.Fragment>
                {pcat && (
                  <React.Fragment>
                    <div style={{ marginTop: 10 }}>
                      <strong>{lastcat.toUpperCase()}</strong>
                    </div>
                  </React.Fragment>
                )}
                <Checkbox
                  style={{ margin: "0" }}
                  checked={ok}
                  onChange={(e) => {
                    let valorsalv = referencia[m.ref].split(",");
                    if (e.target.checked) {
                      var index = valorsalv.indexOf(a.id);
                      if (index < 0) {
                        valorsalv.push(a.id);
                      }
                    } else {
                      var index = valorsalv.indexOf(a.id);
                      if (index > -1) {
                        valorsalv.splice(index, 1);
                      }
                    }
                    valorsalv.sort();
                    valorsalv = valorsalv.map((a, b) => {
                      if (lista.findIndex((h) => h.id === a) >= 0) {
                        return a;
                      }
                    });
                    valorsalv = valorsalv.filter(function (el) {
                      return el != null;
                    });
                    this.saveStateValues(m.ref, valorsalv.join(","), pai);
                  }}
                  key={a.id}>
                  <b>{a.label}</b>
                </Checkbox>
              </React.Fragment>
            );
          })}
        </Column>
      );
    } else if (m.type === "checkboxnexted") {
      let lista = this.state.sublistas[m.listtype] ? this.state.sublistas[m.listtype] : [];
      let valor = referencia[m.ref] ? referencia[m.ref] : "";

      let lastcat = "";
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          {lista.map((a, b) => {
            let ok = false;
            if (valor.indexOf(a.id) >= 0) {
              ok = true;
            }
            let pcat = false;
            if (a.cat !== undefined) {
              if (a.cat !== lastcat) {
                lastcat = a.cat;
                pcat = true;
              }
            }
            return (
              <React.Fragment>
                {pcat && (
                  <React.Fragment>
                    <div style={{ marginTop: 10 }}>
                      <strong>{lastcat.toUpperCase()}</strong>
                    </div>
                  </React.Fragment>
                )}
                <Checkbox
                  style={{ margin: "0" }}
                  checked={ok}
                  onChange={(e) => {
                    let valorsalv = referencia[m.ref];
                    if (e.target.checked) {
                      var index = valorsalv.indexOf(a.id);
                      if (index < 0) {
                        valorsalv.push(a.id);
                      }
                    } else {
                      var index = valorsalv.indexOf(a.id);
                      if (index > -1) {
                        valorsalv.splice(index, 1);
                      }
                    }
                    valorsalv.sort();
                    valorsalv = valorsalv.map((a, b) => {
                      if (lista.findIndex((h) => h.id === a) >= 0) {
                        return a;
                      }
                    });
                    valorsalv = valorsalv.filter(function (el) {
                      return el != null;
                    });
                    this.saveStateValues(m.ref, valorsalv, pai);
                  }}
                  key={a.id}>
                  <b>{a.label}</b>
                </Checkbox>
              </React.Fragment>
            );
          })}
        </Column>
      );
    } else if (m.type === "duploselect") {
      let lista = this.state.sublistas[m.listtype] ? this.state.sublistas[m.listtype] : [];
      let valor = referencia[m.ref] !== undefined ? lista.find((x) => x.id === referencia[m.ref]) : null;

      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <Select
            isDisabled={m.disabled !== undefined ? m.disabled : false}
            ref={m.ref}
            value={valor}
            options={lista}
            onInputChange={(e) => {
              clearTimeout(this.timerId);
              this.timerId = setTimeout(() => {
                this.filtraNovos(e, m.listtype);
              }, 2000);
            }}
            onChange={(e) => {
              this.saveStateValues(m.ref, e.id, pai);
              this.saveStateValues(m.extra, e[m.idextra], pai);
            }}
            isSearchable={m.search !== undefined ? m.search : true}
            placeholder={m.placeHolder}
          />
        </Column>
      );
    } else if (m.type === "select") {
      let lista = this.state.sublistas[m.listtype] ? this.state.sublistas[m.listtype] : [];
      let valor = referencia[m.ref] !== undefined ? lista.find((x) => x.id === referencia[m.ref]) : null;

      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <Select
            isDisabled={m.disabled !== undefined ? m.disabled : false}
            ref={m.ref}
            value={valor}
            options={lista}
            onInputChange={(e) => {
              clearTimeout(this.timerId);
              this.timerId = setTimeout(() => {
                this.filtraNovos(e, m.listtype);
              }, 2000);
            }}
            onChange={(e) => {
              this.saveStateValues(m.ref, e.id, pai);
            }}
            isSearchable={m.search !== undefined ? m.search : true}
            placeholder={m.placeHolder}
          />
        </Column>
      );
    } else if (m.type === "AutoCompleteCity") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <AutoCompleteCity
            autocomplete={this.props.dados.autocomplete.func}
            suggestions={this.props.dados.autocomplete.suggestions}
            valor={referencia[m.ref]}
            change={(e) => {
              this.saveStateValues(m.ref, e, pai);
            }}
            selected={(e) => {
              this.saveStateValues(m.ref, e, pai);
            }}
          />
        </Column>
      );
    } else if (m.type === "autocomplete") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <AutoCompleteHelper
            autocomplete={this.props.autocomplete}
            address={this.state.address}
            endereco={referencia["endereco"]}
            changeAddress={function (address) {
              this.setState({ address });
            }.bind(this)}
            changeEndereco={function (endereco, cidade, estado) {
              this.saveStateValues("endereco", endereco, pai);
            }.bind(this)}
            endSelected={function (endereco, cidade, estado) {
              this.saveStateValues("endereco", endereco, pai);
              if (this.props.geocodeByPlaceId) {
                this.props.geocodeByPlaceId(endereco, null, null).then((result) => {
                  this.saveStateValues("latitude", result.latitude, pai);
                  this.saveStateValues("longitude", result.longitude, pai);
                });
              }
            }.bind(this)}
            title="Endereço"
            center={this.state.center}
          />
        </Column>
      );
    } else if (m.type === "currencydisabled") {
      referencia[m.ref] = referencia[m.ref] === "" ? 0 : referencia[m.ref];
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <NumberFormat
            ref={m.ref}
            value={Number(referencia[m.ref])}
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"R$"}
          />
        </Column>
      );
    } else if (m.type === "color") {
      let usar = !referencia[m.ref] ? "#FFFFFF" : referencia[m.ref];
      console.log("colro " + m.ref, referencia);
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <FormControl value={usar} ref={m.ref} disabled={true} />
          <SketchPicker color={usar} onChangeComplete={({ hex }) => this.saveStateValues(m.ref, hex, pai)} />
        </Column>
      );
    } else if (m.type === "foto64") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <div onClick={() => this.fotoShow(referencia[m.ref], 2)}>
            <Image src={referencia[m.ref] || ""} style={{ marginLeft: "10px", height: 100 }}></Image>
          </div>
          <FormControl
            id="originalFileName"
            type="file"
            inputProps={{ accept: "image/*" }}
            required
            label="Document"
            name="originalFileName"
            onChange={async (event) => {
              let file = event.target.files[0];
              //let base64 = await this.convertBase64(file);
              let base64 = await this.convertBase64resize(file, m.maxWidth ? m.maxWidth : 350, m.maxHeight ? m.maxHeight : 350);
              this.saveStateValues(m.ref, base64, pai);
            }}
            size="small"
            variant="standard"
          />
        </Column>
      );
    } else if (m.type === "currency") {
      referencia[m.ref] = referencia[m.ref] === undefined ? "00.00" : referencia[m.ref];
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <CurrencyInput
            ref={m.ref}
            className="form-control"
            value={(referencia[m.ref] && referencia[m.ref].taxaRetornoFixo) || referencia[m.ref]}
            decimalSeparator=","
            thousandSeparator="."
            prefix="R$ "
            onChangeEvent={(event, maskedvalue, floatvalue) => {
              this.saveStateValues(m.ref, floatvalue, pai);
            }}
          />
        </Column>
      );
    } else if (m.type === "percentage") {
      referencia[m.ref] = referencia[m.ref] === "" ? "00.00" : referencia[m.ref];
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <CurrencyInput
            ref={m.ref}
            className="form-control"
            value={referencia[m.ref]}
            decimalSeparator=","
            thousandSeparator="."
            suffix="%"
            onChangeEvent={(event, maskedvalue, floatvalue) => {
              this.saveStateValues(m.ref, floatvalue, pai);
            }}
          />
        </Column>
      );
    } else if (m.type === "percentagedisabled") {
      referencia[m.ref] = referencia[m.ref] === "" ? "00.00" : referencia[m.ref];
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <NumberFormat
            ref={m.ref}
            value={Number(referencia[m.ref])}
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={"."}
            decimalSeparator={","}
            suffix={"%"}
          />
        </Column>
      );
    } else if (m.type === "km") {
      referencia[m.ref] = referencia[m.ref] === "" ? "00.00" : referencia[m.ref];
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <CurrencyInput
            ref={m.ref}
            className="form-control"
            value={referencia[m.ref]}
            decimalSeparator=","
            thousandSeparator="."
            suffix=" km"
            onChangeEvent={(event, maskedvalue, floatvalue) => {
              this.saveStateValues(m.ref, floatvalue, pai);
            }}
          />
        </Column>
      );
    } else if (m.type === "date") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <DatePicker
            selected={!!referencia[m.ref] ? referencia[m.ref] : moment()}
            className="form-control"
            dateFormat="DD/MM/YYYY"
            onChange={(e) => {
              this.saveStateValues(m.ref, e, pai);
            }}
            name={m.ref}
          />
        </Column>
      );
    } else if (m.type === "datedisabled") {
      if (!referencia[m.ref]) {
        this.saveStateValues(m.ref, moment(), pai);
      }
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <div>{moment(referencia[m.ref]).format("DD/MM/YYYY HH:mm")}</div>
        </Column>
      );
    } else if (m.type === "maskedtext") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <InputMask
            value={referencia[m.ref] || ""}
            ref={m.ref}
            className="form-control"
            mask={m.mask}
            maskChar="_"
            placeholder={m.placeHolder}
            onChange={(e) => {
              this.saveStateValues(m.ref, e.target.value, pai);
            }}
          />
        </Column>
      );
    } else if (m.type === "agencia") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <InputMask
            value={referencia[m.ref] || ""}
            ref={m.ref}
            className="form-control"
            mask={
              referencia[m.banco] !== undefined ? this.state.sublistas[m.tabela].find((x) => x.id === Number(referencia[m.banco])).agencia : "9999"
            }
            maskChar="_"
            placeholder={m.placeHolder}
            onChange={(e) => {
              this.saveStateValues(m.ref, e.target.value, pai);
            }}
          />
        </Column>
      );
    } else if (m.type === "whasapp") {
      return (
        <React.Fragment>
          <Column flexGrow={2} style={{ paddingRight: 15 }}>
            <InputMask
              value={referencia[m.ref] || ""}
              ref={m.ref}
              className="form-control"
              mask={"9999999999999"}
              maskChar="_"
              placeholder={m.placeHolder}
              onChange={(e) => {
                this.saveStateValues(m.ref, e.target.value, pai);
              }}
            />
          </Column>
          <Column flexGrow={2} style={{ paddingRight: 15 }}>
            <span>O Numero do whasapp deve conter o código do país 5541991234567</span>
            <a style={{ display: "table-cell" }} href={"https://api.whatsapp.com/send?phone=" + referencia[m.ref]} target="_blank">
              Testar
            </a>
          </Column>
        </React.Fragment>
      );
    } else if (m.type === "conta") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15, marginTop: 2 }}>
          <InputMask
            value={referencia[m.ref] || ""}
            ref={m.ref}
            className="form-control"
            mask={referencia[m.banco] !== undefined ? this.state.sublistas[m.tabela].find((x) => x.id === Number(referencia[m.banco])).conta : "9999"}
            maskChar="_"
            placeholder={m.placeHolder}
            onChange={(e) => {
              this.saveStateValues(m.ref, e.target.value, pai);
            }}
          />
        </Column>
      );
    } else if (m.type === "telefone") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <InputMask
            value={referencia[m.ref] || ""}
            ref={m.ref}
            className="form-control"
            mask={/^.....9/.test(referencia[m.ref]) ? m.maskCel : m.maskFixo}
            maskChar="_"
            placeholder={m.placeHolder}
            onChange={(e) => {
              this.saveStateValues(m.ref, e.target.value, pai);
            }}
          />
        </Column>
      );
    } else if (m.type === "json") {
      return (
        <Col
          xm={6}
          style={{
            flex: 2,
            flexDirection: "row",
            marginTop: 3,
            marginRight: 3,
          }}>
          {this.renderFields(m, m.ref)}
        </Col>
      );
    } else if (m.type === "jsonarray") {
      // console.log('jsonarray 0', referencia[m.ref])
      return (
        <Column flexGrow={2} style={{ paddingRight: 15, marginTop: 2 }}>
          {this.renderjsonarray(referencia[m.ref], m.acampos)}
        </Column>
      );
    } else if (m.type === "array") {
      let infos = referencia[m.ref] ? referencia[m.ref] : [];
      return (
        <Column flexGrow={2} style={{ paddingRight: 15, marginTop: 2 }}>
          {infos.map((y, z) => {
            return (
              <FormControl
                value={y || ""}
                ref={m.ref + "_" + z}
                type={"text"}
                placeholder={"Incluir Novo"}
                onChange={(e) => {
                  infos[z] = e.target.value;
                  this.saveStateValues(m.ref, infos, pai);
                }}
              />
            );
          })}
          <div><Row>
          <Column flexGrow={2} style={{ paddingRight: 15, marginTop: 2 }}>
          <div
            ref={m.ref + "_p"}
            style={{
              display: "inline-block",
              marginLeft: 3,
            }}
            onClick={() => {
              infos.push("");
              this.saveStateValues(m.ref, infos, pai);
            }}>
            <Icon name={"FaPlusCircle"} size={20} color={"red"} />
          </div></Column>
          {(infos.length > 0) && (
            <Column flexGrow={2} style={{ paddingRight: 15, marginTop: 2 }}>
          <div
            ref={m.ref + "_p"}
            style={{
              display: "inline-block",
              marginLeft: 3,
            }}
            onClick={() => {
              infos = infos.slice(0, -1);
              this.saveStateValues(m.ref, infos, pai);
            }}>
            <Icon name={"FaMinusCircle"} size={20} color={"red"} />
          </div></Column>)}</Row></div>
        </Column>
      );
    } else if (m.type === "jsonlist") {
      //console.log('jsonlist 0')
      return (
        <Column flexGrow={2} style={{ paddingRight: 15, marginTop: 2 }}>
          {this.renderJsonList(referencia[m.ref])}
        </Column>
      );
    } else if (m.type === "checkbox") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15, marginTop: 2 }}>
          <Checkbox
            style={{ margin: "0" }}
            checked={referencia[m.ref] ? true : false}
            onChange={(e) => {
              this.saveStateValues(m.ref, e.target.checked, pai);
            }}>
            <b>{m.label}</b>
          </Checkbox>
        </Column>
      );
    } else if (m.type === "checkboxint") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <Checkbox
            style={{ margin: "0" }}
            checked={referencia[m.ref] ? true : false}
            onChange={(e) => {
              let valor = e.target.checked ? 1 : 0;
              this.saveStateValues(m.ref, valor, pai);
            }}>
            <b>{m.label}</b>
          </Checkbox>
        </Column>
      );
    } else if (m.type === "cep") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <Row>
            <Column>
              <FormControl
                value={referencia[m.ref] || ""}
                ref={m.ref}
                type={m.type}
                maxLength={m.maxLength ? m.maxLength : ""}
                placeholder={m.placeHolder}
                onChange={(e) => {
                  this.saveStateValues(m.ref, e.target.value, pai);
                }}
                onBlur={() => {
                  this.validaCep(m.ref, pai, m);
                }}
              />
            </Column>
            <Column>
              <Image
                src={require("./images/edit.png")}
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  this.validaCep(m.ref, pai, m);
                }}></Image>
            </Column>
          </Row>
        </Column>
      );
    } else if (m.type === "image64") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <div onClick={() => this.fotoShow(referencia[m.ref], 2)}>
            <Image src={referencia[m.ref] || ""} style={{ marginLeft: "10px", height: 100 }}></Image>
          </div>
        </Column>
      );
    } else if (m.type === "foto") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <div onClick={() => this.fotoShow(referencia[m.ref])}>
            <img src={referencia[m.ref]} height={100} />
          </div>
        </Column>
      );
    } else if (m.type === "justlabel") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          {referencia[m.ref] || ""}
        </Column>
      );
    } else if (m.type === "justlabelupper") {
      let texto = "";
      if (referencia[m.ref]) {
        texto = referencia[m.ref];
      }
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          {texto.toUpperCase()}
        </Column>
      );
    } else if (m.type === "disabled") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <FormControl value={referencia[m.ref] || ""} ref={m.ref} disabled={true} />
        </Column>
      );
    } else if (m.type === "hidden") {
      return null;
    } else if (m.type === "number") {
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <FormControl
            value={referencia[m.ref]}
            ref={m.ref}
            type="number"
            step={1}
            placeholder={m.placeHolder}
            onChange={(e) => {
              this.saveStateValues(m.ref, e.target.value, pai);
            }}
          />
        </Column>
      );
    } else if (m.type === "jsonEndereco") {
      return <Column style={{ paddingRight: 15 }}>{this.renderFields(m, m.ref)}</Column>;
    } else if (m.type === "jsonCor") {
      return (
        <Column flex justify-content="space-evenly">
          {this.renderFields(m, m.ref)}
        </Column>
      );
    } else {
      //console.log(m.ref, referencia[m.ref]);
      return (
        <Column flexGrow={2} style={{ paddingRight: 15 }}>
          <FormControl
            value={referencia[m.ref] || ""}
            ref={m.ref}
            type={m.type}
            maxLength={m.maxLength ? m.maxLength : ""}
            placeholder={m.placeHolder}
            onChange={(e) => {
              this.saveStateValues(m.ref, e.target.value, pai);
            }}
          />
        </Column>
      );
    }
  }
  getStateValue(ref, pai) {
    //konsole.log('getStateValue',[ ref, pai])
    let valor = null;
    let r = this.state.record;
    if (pai) {
      valor = r[pai][ref];
    } else {
      valor = r[ref];
    }
    return valor;
  }
  saveStateValues(ref, valor, pai) {
    console.log("saveStateValues", [ref, valor, pai]);
    let r = this.state.record;
    if (pai) {
      r[pai][ref] = valor;
    } else {
      r[ref] = valor;
    }
    this.setState({ record: r });

    if (this.props.dados.geo) {
      this.updateGeo(ref, r);
    }

    if (this.props.dados.mirrorState) {
      this.props.dados.mirrorState({ record: r });
    }
  }
  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {
    if (this.props.dados.parentProps.new) {
      this.newClicked();
    }
    if (this.props.dados.requests.modulos) {
      this.getModulos();
    }
  }
  async salvar() {
    let r = await this.sendsalvar();
    console.log("*********r", r);
    if (r.ok) {
      this.setState({ show: false, new: false, edit: true });
      this.dialog.showAlert(r.msg);
      if (!this.props.dados.onlymodal) {
        this.getList();
      } else {
        if (this.state.after) {
          this.state.after();
          this.setState({ after: null });
        }
      }
    } else {
      this.dialog.showAlert(r.msg);
    }
  }
  async sendsalvar() {
    let saverecords = [];
    let missingFields = [];
    this.props.dados.campos.map((m, i) => {
      if (!m.campos) {
        if (m.presave) {
          return (saverecords = {
            ...saverecords,
            [m.ref]: m.presave(this.state.record[m.ref]),
          });
        } else {
          return (saverecords = {
            ...saverecords,
            [m.ref]: this.state.record[m.ref],
          });
        }
      } else {
        return (saverecords = {
          ...saverecords,
          [m.ref]: JSON.stringify(this.state.record[m.ref]),
        });
      }
    });
    if (saverecords.departamentos && saverecords.departamentos.length > 0) {
      saverecords.centrais[0].departamentos = saverecords.departamentos;
    }
    let allFields = true;
    let error = false;
    let msgError = "";

    for (let i = 0; i < this.props.dados.campos.length; i++) {
      if (
        this.props.dados.campos[i].required &&
        (saverecords[this.props.dados.campos[i].ref] === "" ||
          saverecords[this.props.dados.campos[i].ref] === null ||
          saverecords[this.props.dados.campos[i].ref] === undefined)
      ) {
        missingFields.push(this.props.dados.campos[i].label);
        allFields = false;
      }
    }

    if (!allFields) {
      let missingFieldsList = missingFields.map((field) => <ListItem>{field}</ListItem>);

      let msgError = (
        <>
          <ErrorMessage>Por favor preencha os seguintes campos: </ErrorMessage>
          <List>{missingFieldsList}</List>
        </>
      );

      return { ok: false, msg: msgError };
    }

    if (this.state.new && this.props.dados.requests.save) {
      let result = await this.ws.criarTipo(this.props.dados.requests.type, saverecords, this.props.dados.campos);
      Dialog.resetOptions();
      console.log("*****result********", result);

      if (result.ok) {
        let id = await result.json();
        let s = await this.setSubCadastros(id[0].id);
        if (s.error) {
          error = true;
          msgError = s.msg;
        } else {
          msgError = this.props.dados.tipo_cadastro + " criado com sucesso!";
        }
      } else {
        msgError = "Por favor preencha os campos obrigatórios! - AQUI!!";
      }
      return { ok: !error, msg: msgError };
    } else if (this.state.edit && this.props.dados.requests.edit) {
      let result = await this.ws.alterarTipo(this.props.dados.requests.type, this.state.id, saverecords, this.props.dados.campos);
      Dialog.resetOptions();
      if (result.ok) {
        let s = await this.setSubCadastros();
        if (s.error) {
          error = true;
          msgError = s.msg;
        } else {
          msgError = this.props.dados.tipo_cadastro + " editado com sucesso!";
        }
      } else {
        msgError = "Por favor preencha os campos obrigatórios!";
        error = true;
      }
      return { ok: !error, msg: msgError };
    }
  }
  setSubs(ident, obj) {
    ////konsole.log('setSubs', [ident, this.props.dados.requests.type])
    this.subis[ident] = obj;
  }
  async setSubCadastros(id) {
    //konsole.log('setSubCadastros',id)
    if (!id) {
      id = this.state.id;
    }
    let r = { ok: true, msg: "" };
    if (this.props.dados.subComponente) {
      for (let index = 0; index < this.props.dados.subComponente.length; index++) {
        const e = this.props.dados.subComponente[index];
        this.subis[e.ref](id, e.filtro);
      }
    }
    return r;
  }
  async saveFilho(valor, ref) {
    this.saveStateValues(ref, valor, null);
    return this.sendsalvar();
  }

  async newClicked() {
    admin = "inherit";
    await this.setState({
      show: true,
      new: true,
      edit: false,
      id: 0,
      record: await this.newRecordData(),
      modulosTable: [],
    });
    this.props.dados.campos.map((m, i) => {
      if (m.type === "checkbox") {
        if (!this.state.record[m.ref]) {
          this.setState({
            record: { ...this.state.record, [m.ref]: false },
          });
        }
      }
      return null;
    });
  }
  async newRecordData() {
    let r = [];
    this.props.dados.campos.map((m, i) => {
      if (!m.campos) {
        console.log(m.ref, m.default);
        return (r = {
          ...r,
          [m.ref]: m.default === undefined ? (m.newvalue === undefined ? "" : m.newvalue()) : m.default,
        });
      } else {
        let a = [];
        m.campos.map((x, y) => {
          a = {
            ...a,
            [x.ref]: x.default === undefined ? (x.newvalue === undefined ? "" : x.newvalue()) : x.default,
          };
          return null;
        });
        return (r = { ...r, [m.ref]: a });
      }
    });
    console.log("newRecordData", r);
    //konsole.log('newRecordData',this.props.dados.campos)
    return r;
  }
  async newClickedExterno(after) {
    if (after) {
      this.setState({ after: after });
    }
    await this.orgSubLists();
    this.newClicked();
  }
  async ClickExtertno(dados, after, novo) {
    if (!novo) {
      novo = false;
    }
    let info = { original: dados };
    if (after) {
      this.setState({ after: after });
    }
    this.editClicked(info, novo);
  }
  async editClicked(props, novo) {
    admin = "inherit";
    if (!novo) {
      novo = false;
    }
    let r = [];
    let table = [];
    console.log("editClicked", props);
    this.setState(
      {
        show: true,
        edit: !novo,
        new: novo,
        record: this.newRecordData(),
        id: props.original[this.props.dados.id],
      },
      async () => {
        this.props.dados.campos.map(async (m, i) => {
          if (!m.campos) {
            let v = "";
            if (props.original[m.ref] !== undefined && props.original[m.ref] !== null) {
              v = props.original[m.ref];
            } else {
              if (m.type === "text") {
                v = "";
              }
              if (m.type === "json") {
                v = [];
              }
              if (m.type === "currency") {
                v = 0;
              }
              if (m.type === "km") {
                v = 0;
              }
              if (m.type === "percentage") {
                v = 0;
              }
              if (m.type === "number") {
                v = 0;
              }
              if (m.type === "checkbox") {
                v = false;
              }
              if (m.type === "date") {
                v = moment();
              }
            }
            if (m.type === "date") {
              v = moment(v);
            }
            if (m.tyoe === "foto64") {
              v = await this.resize64(props.original[m.ref] ? props.original[m.ref] : null);
            }
            return (r = { ...r, [m.ref]: v });
          } else {
            let a = [];
            m.campos.map(async (x, y) => {
              let v = "";
              if (props.original[m.ref][x.ref] !== undefined && props.original[m.ref][x.ref] !== null) {
                v = props.original[m.ref][x.ref];
              } else {
                if (!!x.default) {
                  v = x.default;
                }
                if (x.type === "text") {
                  v = "";
                }
                if (x.type === "json") {
                  v = [];
                }
                if (x.type === "checkbox") {
                  v = false;
                }
                if (x.type === "currency") {
                  v = 0;
                }
                if (x.type === "km") {
                  v = 0;
                }
                if (x.type === "percentage") {
                  v = 0;
                }
                if (x.type === "number") {
                  v = 0;
                }
                if (x.type === "date") {
                  v = moment();
                }
              }
              if (x.tyoe === "foto64") {
                v = this.resize64(props.original[m.ref] ? props.original[m.ref] : null);
              }
              if (x.type === "date") {
                v = moment(v);
              }
              console.log("editClicked", [x, v]);

              a = { ...a, [x.ref]: v };
              return null;
            });
            return (r = { ...r, [m.ref]: a });
          }
        });
        if (r.modulos) {
          r.modulos.map((a, j) => {
            this.state.allModulos.map((n, k) => {
              if (a.id === n.id) {
                table.push({ id: n.id, name: n.nome, actions: a.actions });
              }
              return null;
            });
            return null;
          });
          this.setState({ modulosTable: table });
        }

        this.setState({ record: r, original: props.original });
        if (this.props.dados.mirrorState) {
          this.props.dados.mirrorState({ record: r });
        }
      }
    );
  }

  async getList() {
    await this.setState({
      dados: { ...this.state.dados, page: this.state.dados.page - 1 },
    });
    this.fetchData(this.state.dados, this.state.instance);
  }

  modalHide() {
    //this.props.dados.parentProps.history.push("/" + this.props.dados.requests.type)
    this.setState({ show: false, new: false, edit: false });
  }

  getSelectedOptionsValue(selectedOptions) {
    var values = [];

    if (selectedOptions.length <= 0) {
      values = null;
      return values;
    }

    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(selectedOptions[i].value);
    }

    return values[0];
  }

  getSelectedOptionsValueInt(selectedOptions) {
    var values = [];

    if (selectedOptions.length <= 0) {
      values = null;
      return 0;
    }

    for (var i = 0; i < selectedOptions.length; i++) {
      values.push(selectedOptions[i].value);
    }

    return values[0];
  }

  async fetchData(dados, instance) {
    // show the loading overlay

    console.log("fetchData", dados);
    if (this.props.dados.parentProps.setPai) {
      this.props.dados.parentProps.setPai(this.props.dados.requests.type, this.saveFilho);
    }
    await this.orgSubLists();
    //subcadastro init
    if (this.props.dados.parentProps.subcadastro) {
      let filter = this.props.dados.parentProps.filtro;
      let subdata = this.props.dados.parentProps.subdata;
      if (subdata > 0) {
        //konsole.log('loadSubData',[this.props.dados.requests.type, filter,subdata]);
        let filtros = [];
        filtros.push({ id: filter, value: subdata });
        dados.filtered = filtros;
      } else {
        this.newClicked();
        return;
      }
    }
    //subcadastro end
    this.setState({ loading: true, dados: dados, instance: instance });
    // fetch your data
    let order = dados.sorted;
    let result = null;
    let count = 0;
    let filtroz = dados.filtered;
    if (filtroz.length <= 0) {
      if (this.props.dados.requests.defaultfilter !== undefined) {
        filtroz = this.props.dados.requests.defaultfilter;
      }
    }

    if (order.length <= 0) {
      if (this.props.dados.requests.defaultorder !== undefined) {
        order = this.props.dados.requests.defaultorder;
      }
    }
    //konsole.log('fetchData',[this.props.dados.requests.type, dados, instance])
    if (this.props.dados.requests.list) {
      result = await this.ws.getTipo(this.props.dados.requests.type, "", order, ++dados.page, dados.pageSize, filtroz, this.props.dados.campos);
      count = result.headers.get("X-Total-Count");
      result = await result.json();
    }
    this.setState({
      list: result,
      pages: Math.ceil(count / dados.pageSize),
      loading: false,
    });
    //subcadastro init
    if (this.props.dados.parentProps.subcadastro) {
      let k = {};
      if (result[0]) {
        k.original = result[0];
        this.editClicked(k);
      } else {
        this.newClicked();
      }
    }
    //subcadastro end
  }

  fetchDataTimeOut(dados, instance) {
    clearTimeout(this.state.fetchTimeout);
    this.setState({
      fetchTimeout: setTimeout(() => {
        this.fetchData(dados, instance);
      }, 300),
    });
  }
  async funcaoOutras(prop, func) {
    if (func) {
      await func(prop, () => this.getList());
    }
  }
  async externalNew() {
    if (this.props.dados.requests.newOut) {
      await this.props.dados.requests.newOut(() => this.getList());
    }
  }
  async funcaoOutros(func) {
    if (func) {
      await func(this.state.original, () => {
        this.modalHide();
        this.getList();
      });
    }
  }
  render() {
    let user = this.props.dados.parentProps.childProps.userAttrib;
    console.log("user cadastrohelper", user);
    let acessoLeitura = false;
    if (user) {
      if (user.admin) {
        acessoLeitura = true;
      }
      if (user.monitorcentral) {
        acessoLeitura = true;
      }
      if (this.props.dados.requests.semdetalhes) {
        acessoLeitura = false;
      }
    }
    //konsole.log('acessoLeitura',[acessoLeitura, this.props.dados.requests.save, this.props.dados.requests.novo])

    return (
      <div style={{ marginLeft: 100, marginRight: 100 }}>
        {!this.props.dados.parentProps.subcadastro && !this.props.dados.onlymodal && (
          <Breadcrumb>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active href={this.props.dados.href}>
              {this.props.dados.titulo}
            </Breadcrumb.Item>
            {this.props.dados.extrabutton !== undefined && user.admin && (
              <Breadcrumb.Item
                onClick={() => {
                  this.props.dados.extrabutton();
                }}>
                {this.props.dados.extrabuttontexto}
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        )}
        <div>
          {acessoLeitura && this.props.dados.requests.save && this.props.dados.requests.new && !this.props.dados.onlymodal && (
            <Button style={{ margin: "-10px 0 10px 0" }} onClick={this.newClicked.bind(this)}>
              Novo
            </Button>
          )}
          {acessoLeitura && this.props.dados.requests.newOut && (
            <Button style={{ margin: "-10px 0 10px 0" }} onClick={this.externalNew}>
              Novo
            </Button>
          )}

          {!this.props.dados.onlymodal && (
            <ReactTable
              manual
              data={this.state.list}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.fetchDataTimeOut}
              previousText="Anterior"
              nextText="Próxima"
              loadingText="Carregando..."
              noDataText="Nenhum dado encontado"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
              filterable
              defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
              columns={[
                {
                  columns: [
                    ...this.props.dados.table.colunas,
                    {
                      Header: "-",
                      width: 160,
                      filterable: false,
                      sortable: false,
                      style: {
                        cursor: "pointer",
                      },
                      Cell: (props) => (
                        <div>
                          {acessoLeitura && (
                            <Image
                              src={require("./images/edit.png")}
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                this.editClicked(props);
                              }}></Image>
                          )}
                          {acessoLeitura && this.props.dados.requests.delete && (
                            <Image
                              src={require("./images/remove.png")}
                              style={{ marginLeft: "10px" }}
                              onClick={function () {
                                Dialog.setOptions({
                                  defaultOkLabel: "Sim",
                                  defaultCancelLabel: "Não",
                                  primaryClassName: "btn-danger ",
                                });
                                this.dialog.show({
                                  body: "Você tem certeza que deseja deletar??",
                                  actions: [
                                    Dialog.CancelAction(() => {}),
                                    Dialog.OKAction(() => {
                                      this.ws.deleteTipo(this.props.dados.requests.type, props.original[this.props.dados.id]).then((result) => {
                                        Dialog.resetOptions();
                                        if (result.ok) {
                                          if (!this.props.dados.parentProps.subcadastro) {
                                            this.dialog.showAlert(this.props.dados.tipo_cadastro + " deletado com sucesso!");
                                          }
                                          this.getList();
                                        } else {
                                          if (!this.props.dados.parentProps.subcadastro) {
                                            this.dialog.showAlert(
                                              "Não pode deletar! Existem " + this.props.dados.tipo_plural + " cadastradas com esse tipo!"
                                            );
                                          }
                                        }
                                      });
                                    }),
                                  ],
                                  bsSize: "small",
                                  onHide: (dialog) => {
                                    dialog.hide();
                                  },
                                });
                              }.bind(this)}></Image>
                          )}
                          {this.props.dados.requests.outras &&
                            this.props.dados.requests.outras.map((a, b) => {
                              return (
                                <div
                                  key={b}
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 3,
                                  }}
                                  onClick={() => {
                                    this.funcaoOutras(props.row._original, a.funcao);
                                  }}>
                                  <Icon name={a.icone} size={a.tamanho} color={a.cor} />
                                </div>
                              );
                            })}
                        </div>
                      ),
                    },
                  ],
                },
              ]}
              defaultPageSize={5}
              className="-striped -highlight"
            />
          )}
          <div>{versao}</div>
        </div>
        <Dialog
          ref={(el) => {
            this.dialog = el;
          }}
        />
        <Modal
          keyboard={true}
          show={this.state.show}
          onHide={this.modalHide}
          size="xl"
          centered="true"
          container={this}
          aria-labelledby="contained-modal-title"
          dialogClassName="cadastros-modal"
          backdrop="static">
          <Modal.Header>
            {this.state.new && <Modal.Title>Criar {this.props.dados.tipo_cadastro}</Modal.Title>}

            {this.state.edit && <Modal.Title>Editar {this.props.dados.tipo_cadastro}</Modal.Title>}
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form
                onSubmit={(e) => this.submit(e)}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    e.preventDefault();
                    return false;
                  }
                }}>
                {this.renderFields(this.props.dados)}
                {this.state.new && !this.props.dados.parentProps.subcadastro && (
                  <div>
                    {this.props.dados.requests.save && <Button onClick={this.salvar}>Salvar</Button>}
                    <Button onClick={this.modalHide}>Cancelar</Button>
                  </div>
                )}
                {this.state.edit && !this.props.dados.parentProps.subcadastro && (
                  <div>
                    {this.props.dados.requests.save && <Button onClick={this.salvar}>Salvar</Button>}
                    <Button onClick={this.modalHide}>Cancelar</Button>
                    {this.props.dados.requests.outros &&
                      this.props.dados.requests.outros.map((a, b) => {
                        return (
                          <Button
                            onClick={() => {
                              this.funcaoOutros(a.funcao);
                            }}>
                            {a.texto}
                          </Button>
                        );
                      })}
                  </div>
                )}
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          centered="true"
          show={this.state.showfoto}
          onHide={this.fotoHide}
          size="xl"
          container={this}
          aria-labelledby="contained-modal-title"
          backdrop="static">
          <Modal.Body>
            {this.state.fototipo === 1 && <img src={this.state.fotourl} width={500} />}
            {this.state.fototipo === 2 && <img src={this.state.fotourl} width={500} />}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default CadastroHelper;
