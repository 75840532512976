import React, { Component } from 'react';
import WS from '../services/webservice';
import CadastroHelper from '../helper/CadastroHelper.js';

const md5 = require('md5');

export default class CadastroEmpresa extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sublistas: []
    }
    this.ws = new WS();
  }

  mirrorState(key) {
    console.log('mirrorState', key)
    this.setState(key);
  }
  render() {
    return (
      <>
      <CadastroHelper
        dados={{
          parentProps: this.props,
          id: "id",
          tipo_cadastro: "Empresa",
          tipo_plural: "Empresas",
          href: "voucher_empresa",
          titulo: "Cadastro de Empresas",
          larguralabel: 100,
          mirrorState: ((key) => {this.setState(key)}).bind(this),
          geo: {
            rua: 'endereco',
            numero: 'numero',
            bairro: 'bairro',
            cidade: 'cidade',
            estado: 'uf',
            latitude: 'latitude',
            longitude: 'longitude',
          },
          requests: {
            type: 'voucher_empresa',
            list: true,
            save: true,
            new: true,
            edit: true,
            delete: false,
            listSub: true,
            defaultorder: [{ id: "razaosocial" }, { id: "cnpj" }],
          },
          subtipos: [
            {tipo: 'status',p:'oarray', array:[{ txt: 'Ativo', id: 0 }, { txt: 'Inativo', id: 2 }]},
            {tipo: 'tipoportal',p:'oarray', array:[{ txt: 'Normal', id: 0 }, { txt: 'Hotel', id: 1 }]},
          ],
          campos: [
            {
              ref: "razaosocial",
              label: "Razão Social",
              type: "text",
            },
            {
              ref: "cnpj",
              label: "Cnpj",
              type: "text",
            },
            {
              ref: "telefone",
              label: "telefone",
              type: 'text',
            },
            { ref: "cep", label: "CEP", type: "cep", cepdados: {
              uf:"uf",
              cidade: "cidade",
              bairro:"bairro",
              rua:"endereco",
            }},
            {
              ref: "endereco",
              label: "endereco",
              type: 'text',
            },
            {
              ref: "numero",
              label: "numero",
              type: 'integer'
            },
            {
              ref: "complemento",
              label: "complemento",
              type: 'text'
            },
            {
              ref: "bairro",
              label: "bairro",
              type: 'text'
            },
            {
              ref: "cidade",
              label: "cidade",
              type: 'text'
            },
            {
              ref: "uf",
              label: "uf",
              type: 'text'
            },
            {
              ref: "latitude",
              label: "latitude",
              type: 'text'
            },
            {
              ref: "longitude",
              label: "longitude",
              type: 'text'
            },
            {
              ref: 'status',
              label: 'Status',
              type: "select", listtype: 'status',
            },
            {
              ref: 'typeportal',
              label: 'Tipo do Portal',
              type: "select", listtype: 'tipoportal',
            },
            { ref: 'banner', label: 'Banner', type: 'foto64',},
            { ref: "appicon", label: "Icone", type: "foto64",},
            {
              ref: "parceria",
              label: "Código de parceria",
              type: 'text',
              maxlength: 8,
            },

            { ref: "parceriavalor",
              label: "Valor por corrida da parceria", type: "currency", default: 0, },
            { ref: "parceriaicon", label: "Icone Parceria", type: "foto64",},
          ],
          table: {
            colunas: [
              {
                Header: "Razão Social",
                accessor: "razaosocial",
              },
              {
                Header: "Cnpj",
                accessor: "cnpj",
              },
              {
                Header: "Telefone",
                accessor: "telefone",
              },
            ],
          }
        }}
      /></>
    );
  }
}


// {
//   ref: "diavencimento",
//   label: "diavencimento",
//   type: 'integer'
// },
// {
//   ref: 'tipofaturamento',
//   label: 'tipofaturamento',
//   type: 'integer'
// },
// {
//   ref: 'vencimentomensal',
//   label: 'vencimentomensal',
//   type: 'integer'
// },
// {
//   ref: 'vencimentoprimeiraquinzena',
//   label: 'vencimentoprimeiraquinzena',
//   type: 'integer'
// },
// {
//   ref: 'vencimentosegundaquinzena',
//   label: 'vencimentosegundaquinzena',
//   type: 'integer'
// },